import './MessageListSelf.css';

function MessageListSelf(props) {
  return(
    <div className="message-list-self">
        <p className='message-list-self-message'>{props.message.message}</p>
    </div>
  )
}

export default MessageListSelf;
