import './LocationCard.css';
import * as React from 'react'

export default function LocationCard (props) {
  return (
    <div className="location-card-style">
      <img className='location-card-image' src={props.image} />
      <div className='location-card-info'>
        <div className='location-card-title-container'>
          <h1>{props.title}</h1>
          <img src={props.flag} />
        </div>
        <p>{props.description}</p>
      </div>
    </div>
  )
}

