import './Locations.css';
import Header from '../components/Header';
import InfoHeader from '../components/home-page/InfoHeader';
import LocationCard from '../components/locations-page/LocationCard'

function Locations() {

  return(
    <div className="Locations">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <InfoHeader />
      <Header />
      <div className='location-card-container'>
        <LocationCard
          title='UAE'
          description="The United Arab Emirates or the Emirates, is a country in Western Asia. It is located at the eastern end of the Arabian Peninsula, and shares borders with Oman and Saudi Arabia, while having maritime borders in the Persian Gulf with Qatar and Iran. Abu Dhabi is the nation's capital, while Dubai, the most populous city, is an international hub."
          image='assets/holiday-images/abu-dhabi.png'
          flag='assets/flags/UAE.png'
        />
        <LocationCard
          title='The Bahamas'
          description="The Bahamas, known officially as the Commonwealth of The Bahamas, is a sovereign country within the Lucayan Archipelago of the West Indies in the Atlantic. It takes up 97% of the Lucayan Archipelago's land area and is home to 88% of the archipelago's population. The archipelagic state consists of more than 700 islands, cays, and islets in the Atlantic Ocean, and is located north of Cuba and northwest of the island of Hispaniola and the Turks and Caicos Islands, southeast of the American state of Florida, and east of the Florida Keys. The capital is Nassau on the island of New Providence. The Royal Bahamas Defence Force describes The Bahamas' territory as encompassing 470,000 km² of ocean space."
          image='assets/holiday-images/beach-1.png'
          flag='assets/flags/bahamas.png'
        />
        <LocationCard
          title='France'
          description="France, officially the French Republic, is a transcontinental country spanning Western Europe and overseas regions and territories in the Americas and the Atlantic, Pacific and Indian Oceans. Its metropolitan area extends from the Rhine to the Atlantic Ocean and from the Mediterranean Sea to the English Channel and the North Sea; overseas territories include French Guiana in South America, Saint Pierre and Miquelon in the North Atlantic, the French West Indies, and several islands in Oceania and the Indian Ocean. Due to its several coastal territories, France has the largest exclusive economic zone in the world."
          image='assets/holiday-images/eifel-tower.png'
          flag='assets/flags/france.png'
        />
      </div>
      
    </div>
  )
}

export default Locations;
