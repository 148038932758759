import './staff-card.css';
import * as React from 'react'

export default function StaffCard (props) {
  return (
    <div className="staff-card-style">
      <img className='staff-card-image' src={props.image} />
      <h1 className='staff-card-name'>{props.name}</h1>
      <h3 className='staff-card-title'>{props.title}</h3>
      <p className='staff-card-description'>{props.description}</p>
    </div>
  )
}

