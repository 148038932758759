import './Home.css';
import HeaderBanner from '../components/HeaderBanner';
import Header from '../components/Header';
import SlideShow from '../components/image-slide-show/SlideShow';
import InfoHeader from '../components/home-page/InfoHeader';
import HolidayCard from '../components/home-page/HolidayCard';
import InfoCard from "../components/home-page/InfoCard";

function Home() {

  return(
    <div className="Home">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <InfoHeader />
      <Header />
      <SlideShow />
      <a id='holidays'/>
      <div className='holiday-list'>
        <HolidayCard image='/assets/holiday-images/beach-1.png' title='Tiamo Resort' address='The Bahamas' rating={4.9} />
        <HolidayCard image='/assets/holiday-images/Hohenzollern-Castle.png' title='Hohenzollern Castle' address='Germany' rating={4.6} />
        <HolidayCard image='/assets/holiday-images/Stonehenge.png' title='Stonehenge' address='United Kingdom' rating={1} />
        <HolidayCard image='/assets/holiday-images/eifel-tower.png' title='Eiffel Tower' address='France' rating={4.6} />
        <HolidayCard image='/assets/holiday-images/sea-lion.png' title='Tierra Del Fuego' address='Argentina' rating={2} />
        <HolidayCard image='/assets/holiday-images/grand-canyon.png' title='Grand Canyon' address='USA' rating={4} />
        <HolidayCard image='/assets/holiday-images/outback.png' title='The Outback' address='Australia' rating={3} />
        <HolidayCard image='/assets/holiday-images/abu-dhabi.png' title='Abu Dhabi' address='UAE' rating={4.6} />
      </div>
      <div className='bar-separator' />
      <div className='info-card-container'>
        <InfoCard
          image='/assets/atol-protected.png'
          title='Atol Protected'
          content='All our package holidays that include both flights and accommodation come with the extra armour of ATOL protection.'
        />
        <InfoCard
          image='/assets/pound.png'
          title='Price Match Guarantee'
          content='We make sure that all our customers get the price they deserve and do noy have to pay any more than they have too.'
        />
        <InfoCard
          image='/assets/thermo-icon.png'
          title='PCR tests Included'
          content='PCR tests are booked and payed for by us to make sure you have no interruptions through your journey.'
        />
      </div>
      <div className='bar-separator' />
      <div className='footer-logo'>
        <img src='assets/logo.png' />
      </div>
    </div>
  )
}

export default Home;
