import './Header.css';
import * as React from 'react'
import Button from '@mui/material/Button';

export default function Header () {
    const buttonStyle = {
        color: 'white',
        backgroundColor: '#2E2532',
        ":hover": {
            backgroundColor: 'rgba(255, 140, 0, 0.2)'
        }
    }
    return (
        <div className="header-container">
            <Button
            variant='text'
            sx={buttonStyle}
            href='/'
            >Home</Button>
            <Button
            variant='text'
            sx={buttonStyle}
            onClick={() => {
                let holidays = document.getElementById('holidays')
                if (holidays) {
                    holidays.scrollIntoView({ behavior: 'smooth', block: 'start' })
                } else {
                    document.location.href = '/#holidays'
                }
            }}
            >Holidays</Button>
            <Button
            variant='text'
            sx={buttonStyle}
            href='/locations'
            >Locations</Button>
            <Button
            variant='text'
            sx={buttonStyle}
            href='/about-us'
            >About Us</Button>
            <Button
            variant='text'
            sx={buttonStyle}
            href='contact-us'
            >Contact</Button>
            <Button
            variant='text'
            sx={buttonStyle}
            href='chat'
            >Live Chat</Button>
        </div>
    )
}

