import './MessageListOther.css';

function MessageListOther(props) {
  return(
    <div className="message-list-other">
        <p className='message-list-other-message'>{props.message.username}: {props.message.message}</p>
    </div>
  )
}

export default MessageListOther;
