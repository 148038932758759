import './ContactUs.css';
import Header from '../components/Header';
import InfoHeader from '../components/home-page/InfoHeader';

function ContactUs() {

  return(
    <div className="ContactUs">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <InfoHeader />
      <Header />
      <div className='contact-container'>
          <div className='contact-form-container'>
              <h1>Contact Us</h1>
              <form className='contact-form-form'>
                  <label>
                      Subject:<br></br>
                      <input type='text' name='subject' />
                  </label>
                  <br></br>
                  <label>
                      Email:<br></br>
                      <input type='email' name='email' />
                  </label>
                  <br></br>
                  <label>
                      Message:<br></br>
                      <input type='text' name='message' />
                  </label>
                  <br></br>
                  <button>Submit</button>
              </form>
          </div>
      </div>
    </div>
  )
}

export default ContactUs;
