import './HolidayCard.css';
import * as React from 'react'
import Rating from '@mui/material/Rating';

export default function HolidayCard (props) {
    return (
        <div className="holiday-card-style">
            <div className='card-banner-container'>
                <img className='card-banner' src={props.image} />
            </div>
            <div className='card-info-container'>
                <Rating
                    name="text-feedback"
                    readOnly
                    precision={0.1}
                    value={props.rating}
                    />
                <h2>{props.title}</h2>
                <p>{props.address}</p>
            </div>
        </div>
    )
}

