import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './SlideShow.css'

function ReviewCard({ stars, review, name }) {
    console.log(new Array(5))
    return (
        <div className='ReviewCard SlideElement' >
            <h1 style={{ fontSize: '3rem' }} >{review}</h1>
            {
                new Array(stars).map(x => {return(<p key={x}>test</p>)})
            }
            <h1>- {name}</h1>
        </div>
    )
}

export default function SlideShow() {
    return (
        <Slide>
            <div className='SlideOne SlideElement'>
                <h1 className='SlideTitle' >New Deals for the spring Holidays!</h1>
            </div>
            <div className='SlideElement'>
                <h1 style={{ position: 'absolute' }} className='SlideTitle' >Adventure Awaits</h1>
                <img src={'assets/slides/slide1.jpg'} style={{height: '350%', width: '100%'}} />
            </div>
            <div className='SlideTwo SlideElement'>
                <h1 className='SlideTitle' >Start off the year with a 10% discount!</h1>
            </div>
            <ReviewCard className='SlideThree' review={'lorem ipsum doesn\'t work so here is some big text'} name={'Garry'} stars={5} />
        </Slide>
    )
}