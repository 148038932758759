import './AboutUs.css';
import Header from '../components/Header';
import InfoHeader from '../components/home-page/InfoHeader';
import StaffCard from '../components/staff-page/staff-card';

function AboutUs() {

  return(
    <div className="AboutUs">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <InfoHeader />
      <Header />
      <div className='staff-card-content-container'>
        <img className='staff-card-banner' src='assets/ju-gradient.png'></img>
        <img className='staff-card-subheading-img' src='assets/our-history.png' />
        <p className='staff-card-history-para'>Ju-demarken was founded with its customers in mind and has been operating for the last 15 years. With all these years of experience we can cagartue your satisfaction with the services you supply no matter how picky you are. We started out by selling holidays in southern England but has since expanded to international holdidays I every corner of the globe. Even after all of this time over 99% of all our customers where statisfied with their service and we are confident tht this will not be changing anytime soon. Only recently have we implemented our online platform after taking on our new online staff team and hope that it further expands Ju-demarkens reach.</p>
        <img className='staff-card-subheading-img' src='assets/our-team.png' />
        <div className='staff-card-container'>
          <StaffCard 
            image='assets/staff/judith.png'
            name='Judith'
            title='Project Leader'
            description='Judith makes sure that everyone has something to do making sure there is no down time in the project allowing us to supply a premium service as soon as possible.'
            />
          <StaffCard 
            image='assets/staff/debbie.png'
            name='Debbie'
            title='CEO'
            description='Debbie is the team leader making sure that the team works as efficiently as possible. Although she mostly focuses on the buisness side of things she will always drop what she is doing and help you out.'
          />
          <StaffCard 
            image='assets/staff/mark.png'
            name='Mark'
            title='Front End Developer'
            description='Mark focuses on the developing the website for Judemarken and making sure that your user experience is as smooth as possible. He also does a little marketing to let you know of most recent deals.'
          />
          <StaffCard 
            image='assets/staff/ben.png'
            name='Ben'
            title='Back End Developer'
            description='Ben is the one behind the scenes to keep us and our customers connected over the internet. He also helps with replying to emails.'
          />
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
