import './Chat.css';
import { useState, useEffect } from 'react'
import Header from '../components/Header';
import InfoHeader from '../components/home-page/InfoHeader';
import MessageListSelf from '../components/chat/MessageListSelf';
import MessageListOther from '../components/chat/MessageListOther';
import { io } from "socket.io-client";

const socket = io('https://ju-demarken-server.bnji.dev/')
socket.on("connect", () => {
  console.log(socket.id);
});

const getRandomNumber = () => {
  return Math.random().toString().substring(2, 8);
}

const userid = getRandomNumber()

function Chat() {
  const [messages, setMessages] = useState([])
  const [username, setUsername] = useState('User')
  const [messageContent, setMessageContent] = useState('')

  useEffect(() => {
    socket.on('message', message => {
      console.log('receieved message\n', message);
      setMessages(prev => [...prev, {username: message.username, message: message.message, userid: message.userid}])
    })
  },[])

  const handleUsernameChange = event => {
    setUsername(event.target.value)
  }

  const handleMessageContentChange = event => {
    setMessageContent(event.target.value)
  }

  const sendMessage = event => {
    event.preventDefault()
    if (messageContent == '' || username == '') {
      return
    }
    socket.emit('message', {
      username: username,
      message: messageContent,
      userid: userid
    })
    setMessages(prev => [...prev, {username: username, message: messageContent, userid: userid}])
  }

  return(
    <div className="chat">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <InfoHeader />
      <Header />
      <p className='chat-info'>Note to marker: To see this working open another tab of this page or get someone else to open this page!</p>
      <div className='chat-container'>
        {
          messages.map(x => {
            if (x.userid == userid) {
              console.log(userid, ' : ', x.userid);
              return(<MessageListSelf message={x} />)
            } else {
              return(<MessageListOther message={x} />)
            }
          })
        }
      </div>
      <form className='chat-message-form' onSubmit={sendMessage}>
        <label>
          Username:
          <input type='text' value={username} onChange={handleUsernameChange} />
        </label>
        <br></br>
        <label>
          Message:
          <input type='text' value={messageContent} onChange={handleMessageContentChange} />
        </label>
        <br></br>
        <input type='submit' value='Submit' />
      </form>
    </div>
  )
}

export default Chat;
